@import "../../styles/variable";
.sider-group{
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
.menu-box{
  flex: 1 1 auto;
  overflow: scroll;
  -ms-overflow-style: none;
}
.logo {
  flex: 0 0 64px;
  width: 100%;
  height: 64px;
  background: transparent;
  position: relative;
  background-size: 140px;
  -webkit-transition: background-size .3s cubic-bezier(.645,.045,.355,1),width .3s cubic-bezier(.645,.045,.355,1);
  transition: background-size .3s cubic-bezier(.645,.045,.355,1),width .3s cubic-bezier(.645,.045,.355,1);

  &.collapsed{
    background-size: 56px;
    -webkit-transition: background-size .3s cubic-bezier(.645,.045,.355,1),width .3s cubic-bezier(.645,.045,.355,1);
    transition: background-size .3s cubic-bezier(.645,.045,.355,1),width .3s cubic-bezier(.645,.045,.355,1);
  }

  &:after{
    display: block;
    content: ' ';
    height: 1px;
    width: 100%;
    background-color: #eee;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.shadow-box{
  box-shadow: 10px 0 10px 0 #dddddd;
}
.site-layout {
  background: #f8f5f5;
  //height: 100vh;
  overflow: hidden;
}
.site-layout .site-layout-background {
  background: transparent;
}
.content-box{
  flex: 1 1 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  //margin-bottom: 64px;
  overflow: scroll;
}
.content-box2{
  flex: 1 1 auto;
  padding: 0;
  margin: 0;
  overflow: hidden;
  -ms-overflow-style: none;
}
.menu-box::-webkit-scrollbar, .content-box::-webkit-scrollbar, .content-box2::-webkit-scrollbar {display:none}
.content-box2 > iframe{
  width: 100%;
  height: 100%;
  border: 0;
}
.breadcrumb-cus{
  font-size: 24px;
  margin-bottom: 20px;
}
:global{
  .ocss-trigger{
    font-size: 17px;
  }
  .ant-layout-header{
    flex: 0 0 64px;
    border-bottom: solid 1px #eee!important;
  }
  .ant-layout-header .ant-menu{
    line-height: 61px;
  }
  .ant-layout-footer{
    flex: 0 0 46px;
    padding: 14px 20px;
    background: #f1f0f0;
    color: #979797;
  }
  .ant-layout-footer > span:first-child{
    float: left;
  }
  .ant-layout-footer > span:last-child{
    float: right;
  }
  .ant-layout-header,.ant-layout-sider-trigger ,.ant-layout-sider{
    background: #fff;
    color:#000;
    border-bottom: 1px solid #dddddd;
  }
  .ant-btn-background-ghost{
    color:#000000;
  }
  .ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu{
    // border-bottom: 1px solid #dddddd;
    top :0;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background: @main-color;
  }
  .ant-menu-item-selected a, .ant-menu-item-selected a:hover{
    color:#ffffff;
  }
  .ant-menu-item-selected .anticon{
    color:#ffffff;
  }
}

.spin-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@menu-highlight-color: #D81924;@menu-item-active-bg: #D81924;@table-header-bg: #F6F6F6;@breadcrumb-base-color: rgba(0, 0, 0, 0.65);@breadcrumb-last-item-color: #000;