.wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 48px;
  left: 0;
  background: url('./assets/bg.jpg') no-repeat center center;
  background-size: cover;
}

.logo {
  height: 53px;
}

:global{
  .logo {
    height: 53px;
    width: 165px;
    background-image: url('./assets/logo.png');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100%;
  }
  .logo2{
    background-image: url('./assets/system.png');
  }
  div.login-wrapper {
    width: 428px;
    margin-right: 168px;
    padding: 26px 26px 0 26px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 9px 43px 0 #ccc;

    .ant-form-item {
      margin-bottom: 20px;
    }

    .ant-input-affix-wrapper {
      line-height: 34px;
      border-radius: 4px;
    }

    .captcha {
      position: absolute;
      right: 0;
      top: 8px;

      img {
        width: 69px;
        height: 28px;
      }
    }

    .ant-btn {
      height: 50px;
      font-size: 20px;
    }
  }

  /*#root .ant-layout-footer {
    z-index: 1;
  }*/
}

@menu-highlight-color: #D81924;@menu-item-active-bg: #D81924;@table-header-bg: #F6F6F6;@breadcrumb-base-color: rgba(0, 0, 0, 0.65);@breadcrumb-last-item-color: #000;