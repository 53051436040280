/** 公共样式，影响全局 **/
@import "variable";
html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  background-color: #f8f5f5 !important;
}

#root {
  height: 100%;
  background: #f8f5f5;
}

/*.App {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}
.footer {
  display: flex;
  width: 100%;
  height: 50px;
  padding: 14px 15px;
  justify-content: space-between;
  align-items: center;
  background: #f1f0f0;
  color: #979797;
}
.main{
  flex: 1 1 auto;
  margin: 20px;
}*/
.app-body {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 0 2px 2px rgba(204, 204, 204, 0.2);
  flex: auto;
}

.page-main-box {
  padding: 20px 24px;
}
.top-search-box {
  padding: 20px 24px 0!important;
  background: #f5f5f5;
  border-bottom: solid 1px #ddd;
}
.form-box-lg {
  width: 90%;
  margin: 0 auto;
}
.form-box-m {
  width: 60%;
  margin: 0 auto;
}
.border-1{
  border: solid 1px #ddd;
}
.page-content-title {
  //margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #e8e8e8;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  font-size: 16px;
  color: #333;
}
.page-content-title.right-box {
  position: relative;
}
.page-content-title.right-box:before {
  content: '';
  position: absolute;
  top: 0;
  left: -40px;
  border: 20px solid;
  border-color: transparent;
  border-right-color: #e8e8e8;
}
.my-th,.my-td{
  position: relative;
  display: table-cell;
  vertical-align: middle;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  text-align: left;
  padding: 8px;
  //padding: 12px 8px;
  overflow-wrap: break-word;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
  -webkit-transition: background .3s ease;
  transition: background .3s ease;
}
.my-td{
  background: #ffffff;
}
.my-th:last-child,.my-td:last-child{
  border-right: none;
}
.my-require{
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
}
.my-editor{
  border: 1px solid #d1d1d1;
  border-radius: 5px;
}
/*.ant-form-item-has-error .my-editor{
  border-color: #ff4d4f;
}*/
.mask{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  overflow: hidden;
  background: transparent;
}
/**
 * 以下是对antd组件样式的修改，适用于特殊情况。
 * 不要直接修改antd组件样式，可在antd组件CSS类的基础上添加新类控制新样式的影响范围，如：.gray-header.ant-page-header。
 * 定制化antd组件时，请尽量在theme.js中修改antd全局样式
 */
//新增antd Button组件的type属性：<Button type='gray'>新增</Button>
.ant-btn-gray {
  color: #fff !important;
  background: #999 !important;
  border-color: #999 !important;
}
.ant-btn-danger {
  color: #fff !important;
  background: @main-color !important;
  border-color: @main-color !important;
}

.ant-select-show-search.ant-select-multiple.ant-select-disabled .ant-select-selector {
  cursor: not-allowed;
}

.gray-header.ant-page-header {
  background-color: #f5f5f5 !important;
  padding: 10px 24px !important;
  border-bottom: solid 1px #ddd;
}

.gray-header.ant-page-header .ant-page-header-heading-extra {
  position: relative;
}

.pos-bottom.ant-btn {
  position: absolute;
  bottom: -15px;
  right: 0;
}
.gray.ant-tabs {
  border-top-right-radius: inherit;
  border-top-left-radius: inherit;
}

.gray.ant-tabs > .ant-tabs-nav {
  border-bottom: solid 1px #ddd;
  background: #f7f7f7 !important;
}

.gray.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  background: #f7f7f7 !important;
}
.gray.ant-tabs .ant-tabs-ink-bar {
  background: @main-color;
}
.gray.ant-tabs .ant-tabs-tab.ant-tabs-tab-active,
.gray.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: @main-color;
}
.mb0.ant-tabs .ant-tabs-nav{
  margin-bottom: 0;
}

.absolute {
  position: absolute;
}
.relative {
  position: relative;
}

.fl {
  float: left;
}
.fr {
  float: right;
}

.fs14 {
  font-size: 14px;
}
.fs12 {
  font-size: 12px;
}

.tc {
  text-align: center;
}

.tr50 {
  top: 50%;
}
.lr50 {
  left: 50%;
}

.wr100 {
  width: 100%;
}
.w100 {
  width: 100px;
}
.w200 {
  width: 200px;
}
.w300 {
  width: 300px;
}
.w400 {
  width: 400px;
}

.hr100 {
  height: 100%;
}

.pd10 {
  padding: 10px;
}
.pd20 {
  padding: 20px;
}
.pr10 {
  padding-right: 10px;
}
.pr20 {
  padding-right: 20px;
}

.mg-at {
  margin: auto;
}
.mg10 {
  margin: 10px;
}
.mg20 {
  margin: 20px;
}
.mb0 {
  margin-bottom: 0px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 30px;
}
.mb40 {
  margin-bottom: 40px;
}
.mt0 {
  margin-top: 0px;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mt40 {
  margin-top: 40px;
}
.ml80 {
  margin-left: 80px;
}

.red {
  color: @main-color;
}
.white {
  color: #fff;
}
.gray {
  color: gray;
}
.black {
  color: #000;
}
.cl333 {
  color: #333;
}

.bg-red {
  background-color: @main-color;
}
.bg-white {
  background-color: #fff;
}
.bg-gray {
  background-color: gray;
}
.bg-black {
  background-color: #000;
}
.no-border{
  border: 0;
}
.none {
  display: none;
}

.content-wrapper {
  margin-left: 0 !important;
}

// 导航栏样式
.ant-layout-header .ant-menu-item .anticon {
  font-size: 18px;
}
.ant-layout-header .ant-btn {
  border: 0;
}

@menu-highlight-color: #D81924;@menu-item-active-bg: #D81924;@table-header-bg: #F6F6F6;@breadcrumb-base-color: rgba(0, 0, 0, 0.65);@breadcrumb-last-item-color: #000;