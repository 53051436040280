
:global{
  .ant-layout {
    background-color: #fff;

    .ant-layout-header, .ant-layout-sider-trigger, .ant-layout-sider {
      border-bottom: 0;
    }
  }
  .asyn-menu {
    color: #3a3a3a;
    border-right: none;

    /*.ant-menu-item-selected{
      background: none!important;
      background-image: linear-gradient(153deg, #00A5F3 0%, #006CE2 100%)!important;
      border-radius: 1px;
    }*/
    .ant-menu-item-selected i {
      //color: #e6f7ff;
      color: #fff;
    }
    .ant-menu-submenu {
      &.ant-menu-submenu-open .ant-menu-sub{
        background-color: #F4F7FA;
      }
    }
    .ant-menu-item > i:nth-child(1), .ant-menu-submenu-title > i:nth-child(1) {
      min-width: 14px;
      margin-right: 10px;
      font-size: 14px;
      -webkit-transition: font-size .15s cubic-bezier(.215,.61,.355,1),margin .3s cubic-bezier(.645,.045,.355,1);
      transition: font-size .15s cubic-bezier(.215,.61,.355,1),margin .3s cubic-bezier(.645,.045,.355,1);
    }
    .ant-menu-item i+span, .ant-menu-submenu-title i+span {
      opacity: 1;
      -webkit-transition: opacity .3s cubic-bezier(.645,.045,.355,1),width .3s cubic-bezier(.645,.045,.355,1);
      transition: opacity .3s cubic-bezier(.645,.045,.355,1),width .3s cubic-bezier(.645,.045,.355,1);
    }

    &.ant-menu-inline-collapsed>.ant-menu-item i, &.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title i{
      margin: 0;
      font-size: 18px;
      line-height: 40px;
    }
    &.ant-menu-inline-collapsed>.ant-menu-item i+span, &.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title i+span {
      display: inline-block;
      max-width: 0;
      opacity: 0;
    }
  }
  .ant-menu-inline-collapsed-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content{
    background: #fff;
  }
  .ant-menu-inline-collapsed-tooltip .ant-tooltip-inner{
    background: #fff;
    color: #3a3a3a;
    width: 160px;

    a{
      color: #3a3a3a;
    }
  }
}

@menu-highlight-color: #D81924;@menu-item-active-bg: #D81924;@table-header-bg: #F6F6F6;@breadcrumb-base-color: rgba(0, 0, 0, 0.65);@breadcrumb-last-item-color: #000;